<template lang="pug">
  jumbotron
    template(v-slot:content)
      appDatepicker(
        :value="$route.query[JUMBOTRON_FILTERS.COURSE_DATE_AFTER]"
        label="Course From"
        @input="setFilter(JUMBOTRON_FILTERS.COURSE_DATE_AFTER, $event)"
        @change="setFilter(JUMBOTRON_FILTERS.COURSE_DATE_AFTER, $event)"
        @clear="resetFilter(JUMBOTRON_FILTERS.COURSE_DATE_AFTER, $event)"
        no-title
        calendar
        :max="getToday"
      )

      appDatepicker(
        :value="$route.query[JUMBOTRON_FILTERS.COURSE_DATE_BEFORE]"
        label="Course To"
        @input="setFilter(JUMBOTRON_FILTERS.COURSE_DATE_BEFORE, $event)"
        @change="setFilter(JUMBOTRON_FILTERS.COURSE_DATE_BEFORE, $event)"
        @clear="resetFilter(JUMBOTRON_FILTERS.COURSE_DATE_BEFORE, $event)"
        no-title
        calendar
        :max="getToday"
      )

      app-select(
        :options="crmCourseCities"
        :value="+$route.query[JUMBOTRON_FILTERS.CITY]"
        filter
        @input="setFilter(JUMBOTRON_FILTERS.CITY, $event)"
        placeholder="Course City"
        slug-key='ID'
        slug-value='name'
        searchable
        show-close-btn
      )

      app-select(
        :options="crmCourseLeaders"
        :value="+$route.query[JUMBOTRON_FILTERS.COURSE_LEADER]"
        filter
        @input="setFilter(JUMBOTRON_FILTERS.COURSE_LEADER, $event)"
        placeholder="Course Leader"
        slug-key='id'
        slug-value='name'
        searchable
        show-close-btn
      )
</template>

<script>
import { mapGetters } from 'vuex';
import appSelect from '@/components/global/actions/BaseSelect.vue';
import { JUMBOTRON_FILTERS } from '../core/feedback-const'
import appDatepicker from '@/components/global/fields/appDatepicker.vue'

export default {
  name: 'FeedbackFilters',

  inject: ['setQuery'],

  props: {
    loading: { type: Boolean, default: false }
  },

  data: () => ({
    JUMBOTRON_FILTERS
  }),

  computed: {
    ...mapGetters({
      crmCourseCities: 'crmCourseCities/items',
      crmCourseLeaders: 'crmCourseLeaders/leadersList',
    }),

    getToday() {
      return this.$dates().format('YYYY-MM-DD')
    }
  },

  async mounted() {
    await this.dispatchLeadersList()
  },

  methods: {
    async dispatchLeadersList() {
      await this.$store.dispatch('crmCourseLeaders/leadersList', {cache: true})
    },

    async setFilter(type, payload, load = true) {
      if (this.loading) return
      if (this.$route.query[type] == payload) return

      if (!payload) return await this.setQuery({[type]: undefined}, load)
      await this.setQuery({[type]: payload}, load);
    },

    resetFilter(type) {
      if (this.loading) return
      if (!this.$route.query[type]) return
      this.setFilter([type], undefined)
    }
  },

  components: {
    appSelect,
    appDatepicker,
    jumbotron: () => import('@/components/global/fields/Jumbotron.vue')
  }
}
</script>